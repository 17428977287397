import ServiceDashboard from "@/api/serviceDashboard";
import AddService from "@/api/serviceAdd";

const state = () => ({
  user: {
    api_key: "",
    balance: null,
    ban_reason: "",
    campaign_limit: false,
    email: "",
    enable_ar: null,
    g2a: 1,
    id: null,
    invoice_data: "",
    invoice_name: "",
    manager: {},
    name: "",
    news: 0,
    notice: 0,
    notice_limit: 12312312,
    on_page: 20,
    ref_code: "",
    rid: 0,
    roles: ["ROLE_USER"],
    skype: "",
    telegram: "",
    telegram_id: null,
    tickets: 0,
  },
  featureFlags: [],
  referralId: "",
  canCloseTooltips:
    localStorage.getItem("closeTooltips") === "true" ? true : false,
  tooltips: localStorage.getItem("tooltips") === "true" ? true : false,
  isGuestMode: false,
});

const getters = {
  getFeatureFlags: (state) => {
    return state.featureFlags;
  },
  getUser: (state) => {
    return state.user;
  },
  canCloseTooltips: (state) => {
    if (!localStorage.getItem("closeTooltips"))
      localStorage.setItem("closeTooltips", "true");
    return state.canCloseTooltips;
  },
  getTooltips: (state) => {
    if (!localStorage.getItem("tooltips"))
      localStorage.setItem("tooltips", "true");
    return state.tooltips;
  },
  getReferralId: (state) => {
    return state.referralId;
  },
  isGuestMode: (state) => {
    return state.isGuestMode;
  },
};

const mutations = {
  setUserStore(state, payload) {
    if (payload) {
      if (payload.is_email_verified === false) {
        payload.is_email_verified = true;
      }
      state.user = payload;
    }
  },
  setFeatureFlags(state, payload) {
    if (payload) {
      state.featureFlags = payload.data;
    }
  },
  setUserReferralId(state, payload) {
    if (payload) {
      state.referralId = payload;
    }
  },
  setCanCloseTooltips(state, payload) {
    localStorage.setItem("closeTooltips", payload);
    state.canCloseTooltips = payload;
  },
  setUserTooltips(state, payload) {
    state.tooltips = payload;
    if (payload === true) localStorage.setItem("closeTooltips", "true");
    localStorage.setItem("tooltips", payload);
  },
  setGuestMode(state, payload) {
    state.isGuestMode = payload;
  },
};

const actions = {
  async setUser({ commit }) {
    await ServiceDashboard.getUser().then((result) => {
      commit("setUserStore", result);
    });
  },
  async setFeatureFlags({ commit }) {
    await AddService.isEnabledFeatureFlag().then((result) => {
      if (result && result.status === 200) commit("setFeatureFlags", result);
    });
  },
  setUserReferral({ commit }, payload) {
    commit("setUserReferralId", payload);
  },
  setTooltips({ commit }, payload) {
    commit("setUserTooltips", payload);
  },
  disableTooltips({ commit }, payload) {
    commit("setCanCloseTooltips", payload);
  },
  getGuestMode({ state, commit, dispatch }) {
    if (localStorage.getItem("guestToken") && !state.isGuestMode) {
      commit("setGuestMode", true);
    }
    if (state.isGuestMode && !localStorage.getItem("guestToken")) {
      commit("setGuestMode", false);
      dispatch("setUser");
    }
  },
  logOutGuestMode({ commit }) {
    localStorage.removeItem("guestToken");
    commit("setGuestMode", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
